
<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mainForm">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Instruktioner
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li>Välj typ av inlägg.</li>
            <ul>
              <li>Event: Är det en tillställning med tid och plats? Då är det ett event.</li>
              <li>Application: Finns det ett formulär men inget event kopplat till det? Då är det en application.</li>
              <li>News: Är det enbart information? Då är det en news.</li>
            </ul>
            <li>Skriv titel, undertitel och brödtext på svenska och engelska.</li>
            <ul>
              <li>Brödtexten kan formateras med <a href="https://www.markdownguide.org/cheat-sheet">Markdown</a>.</li>
            </ul>
            <li>Lägg till bild (frivilligt).</li>
            <ul>
              <li>Du kan antingen länka till en bild eller ladda upp en egen bild.</li>
              <li>Bilden kan antingen visas ovanför (stor bild) eller i texten (liten bild).</li>
            </ul>
            <li>Lägg till formulär (för event och application).</li>
            <ul>
              <li>Lägg till öppnings- och stängningstid for formuläret.</li>
              <li>Om formuläret är på forms.futf.se behöver du bara ange ID:t. Men även externa formulär går att ange som en URL.</li>
            </ul>
            <li>Lägg till plats och tid (för event)</li>
            <li>Välj publiceringstid.</li>
            <ul>
              <li>Antingen publicerar du direkt eller så väljer du ett datum och klockslag när det ska publiceras.</li>
              <li>Inlägg med publiceringsdatum i framtiden kan inte ses om man inte är inloggad förräns tidpunkten passerats.</li>
            </ul>
            <li>Förhandsgranska inlägget.</li>
            <ul>
              <li>Längst ner på sidan ser du en preview av hur inlägget kommer se ut på svenska och engelska</li>
              <li>Tryck på "Läs mer" för att se brödtexten.</li>
            </ul>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Kategori -->
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <v-select
          v-model="category"
          :items="categories"
          :rules="[(v) => !!v || 'Item is required']"
          label="Kategori"
          filled
          required
        ></v-select>
      </v-col>
    </v-row>
    <!-- Titel -->
    <div v-if="category">
      <v-card class="px-4 py-1 my-2">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title"
              :counter="24"
              :rules="namnRules"
              label="Titel"
              required
            ></v-text-field>
          </v-col>
          <!-- Titel på engelska -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title_en"
              :counter="24"
              :rules="enNamnRules"
              label="Titel engelska"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Subtitle -->
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="subtitle"
              :counter="200"
              :rules="subtitleRules"
              label="Undertext"
              required
            ></v-text-field>
          </v-col>
          <!-- subtitle på engelska -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="subtitle_en"
              :counter="200"
              :rules="enSubtitleRules"
              label="Undertext engelska"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Fritext -->
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="text"
              :counter="10000"
              :rules="textRules"
              label="Text"
              required
            >
              <template v-slot:label>
                <div>Text <small>(huvudtexten)</small></div>
              </template>
            </v-textarea>
          </v-col>
          <!--  Engelsk fritext -->
          <v-col cols="12" md="6">
            <v-textarea
              v-model="text_en"
              :counter="10000"
              :rules="enTextRules"
              label="Text engelska"
            >
              <template v-slot:label>
                <div>Text engelska <small>(huvudtexten)</small></div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-card>
      <!-- Bilder -->
      <v-card class="px-4 py-1 my-2">
        <v-radio-group v-model="eventImg">
          <v-radio label="Ingen bild" value="ingen"></v-radio>
          <v-radio label="URL" value="url"></v-radio>
          <v-radio label="Ladda upp" value="ladda upp"></v-radio>
        </v-radio-group>

        <v-text-field
          v-if="eventImg==='url'"
          v-model="image"
          :counter="1000"
          :rules="imageRules"
          label="Bild URL..."
        ></v-text-field>

        <div v-if="eventImg==='ladda upp'">
          <v-file-input
            accept="image/*"
            label="Ladda upp bild"
            @change="imageUpload"
          ></v-file-input>

          <div v-if="successAlert">
            {{ successMessage }}
          </div>
          <div v-if="errorAlert">
            {{ errorMessage }}
          </div>
          <div v-html="uploadedImage" align="center"></div>
        </div>

        <div v-if="eventImg!=='ingen'">
          <v-checkbox
            label="Stor bild ?"
            v-model="bigimg"
          ></v-checkbox>
        </div>
      </v-card>
      <!-- Plats endast relevant för event -->
      <v-card class="px-4 py-1 my-2" v-if="category === 'event'">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location"
              :counter="24"
              :rules="namnRules"
              label="Plats"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location_en"
              :counter="24"
              :rules="enNamnRules"
              label="Plats engelska"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <date-time-picker
              v-model="eventDateTime"
              dateLabel="Datum för event"
              timeLabel="Tid för event">
            </date-time-picker>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="px-4 py-1 my-2">
        <v-checkbox
          label="Formulär?"
          v-model="hasForm"
        ></v-checkbox>
        <div v-if="hasForm">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <date-time-picker
                v-model="formOpenDateTime"
                dateLabel="Form öppningsdatum"
                timeLabel="Form öppningstid"
                nullable
              >
              </date-time-picker>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="6" md="6">
              <date-time-picker
                v-model="formCloseDateTime"
                dateLabel="Form stängningsdatum"
                timeLabel="Form stängningstid"
                nullable
              >
              </date-time-picker>
            </v-col>
          </v-row>

          <v-row>
            <!-- TOTAL KAOS, PREPARE -->
            <v-col cols="6" md="3">
              <v-text-field
                v-model="form_id"
                :counter="24"
                label="Form ID (om på forms.futf.se)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                v-model="form_link"
                :counter="1000"
                label="eller extern form länk"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-card class="px-4 py-1 my-2">
        <v-radio-group v-model="scheduleRadioGroup">
          <v-radio label="Publicera nu" value="now"></v-radio>
          <v-radio label="Schemalägg" value="schedule"></v-radio>
        </v-radio-group>
        <date-time-picker
              v-model="scheduledDateTime"
              dateLabel="Publiceringsdatum"
              timeLabel="Publiceringstid"
              v-if="scheduleRadioGroup == 'schedule'"
              >
        </date-time-picker>
      </v-card>

      <v-btn :disabled="!valid" color="success" class="ma-3" @click="submitEvent">
        Skicka
      </v-btn>

      <v-row>
        <v-col sm="12" md="6" v-for="lang in ['swe', 'eng']" :key="lang">
          <newscard
            :title="title"
            :title_en="title_en"
            :subtitle="subtitle"
            :subtitle_en="subtitle_en"
            :text="textRendered"
            :text_en="textEnglishRendered"
            :category="category"
            :publishedTime="currentTime"
            :eventtime="category === 'event' ? eventTimeZulu : null"
            :location="location"
            :location_en="location_en"
            :form="form"
            :image="image"
            :bigimg="bigimg"
            :language="lang"
          ></newscard>
        </v-col>
      </v-row>
    </div>

    <div v-if='eventLoading' class='center'>
      <vue-spinner />
    </div>
  </v-form>
</template>

<script>
/* eslint-disable */
let markdown = require('markdown-it')();
import newscard from '@/components/Newscard.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import { mapGetters, mapActions } from 'vuex'
import Spinner from 'vue-simple-spinner'
import { notify } from '../utils/notification'
import axios from 'axios'
import { getAuthHeader } from '../utils/tools'


export default {
  data: () => ({
    categories: ["event", "application", "news"],
    valid: true,
    title: "",
    title_en: "",
    subtitle: "",
    subtitle_en: "",
    text: "",
    text_en: "",
    category: null,
    eventtime: "",
    eventImg: "ingen",
    location: "",
    location_en: "",
    image: "",
    fileData: new FormData(),
    fileSize: null,
    eventDateTime: null,
    formOpenDateTime: null,
    formCloseDateTime: null,
    successAlert: false, // related to image
    errorAlert: false, // --"--
    errorMessage: "", // --"--
    successMessage: "", // --"--
    uploadedImage: "", // --"--
    bigimg: false,
    hasForm: false,
    form_id: "",
    form_link: "",
    show1: false,
    scheduleRadioGroup: "now",
    scheduledDateTime: null,
    namnRules: [
      (v) => !!v || "Måste fyllas i",
      (v) => (v && v.length <= 100) || "Mindre än 100 bokstäver på namnet, tack",
    ],
    textRules: [
      (v) => !!v || "Glöm inte texten",
      (v) =>
        (v && v.length <= 10000) || "Mindre än 10000 bokstäver på texten, tack",
    ],
    subtitleRules: [
      (v) => !!v || "Glöm inte undertexten",
      (v) =>
        (v && v.length <= 200) ||
        "Mindre än 200 bokstäver på undertexten, tack",
    ],
    enNamnRules: [
      (v) => v.length <= 100 || "Mindre än 100 bokstäver på namnet, tack",
    ],
    enTextRules: [
      (v) => v.length <= 10000 || "Mindre än 10000 bokstäver på texten, tack",
    ],
    enSubtitleRules: [
      (v) => v.length <= 200 || "Mindre än 200 bokstäver på undertexten, tack",
    ],
    imageRules: [
      (v) => v.length <= 1000 || "Mindre än 1000 bokstäver på bilden, tack",
    ],
    bigImgRules: [(v) => !!v || "Måste väljas"],
    timeRules: [
      (v) => !!v || "Glöm inte datumet",
      (v) => (v && v.length <= 50) || "Mindre än 50 bokstäver på tiden, tack",
    ],
  }),
  components: {
    newscard,
    DateTimePicker,
    vueSpinner: Spinner
  },
  props: {
    eventID: Number
  },
  mounted: function () {
    // Check if we are editing an existing event
    // if so, insert its values into all the fields.
    if (this.isEditing()) {
      axios
        .get('/api/events/allAdmin', getAuthHeader())
        .then((result) => {
          const data = result.data
          for (const element of data) {
            if (element.id === this.eventID) {
              console.log("Match")
              this.populateForm(element)
              return
            }
          }
          // If we reach here, it means we didn't find the event
          alert('No event found with id: ' + this.eventID)
          this.$router.push('/adminEventList')
        })
    }
  },
  computed: {
    ...mapGetters(['notification', 'eventLoading']),
    currentTime() {
      return new Date().toISOString()
    },
    form() {
      if (!this.hasForm) {
        return undefined
      }
      let d = { id: this.form_id, opentime: this.formOpenTimeZulu, closetime: this.formCloseTimeZulu }
      console.log(d)
      return d
    },
    textRendered() {
      const rendered = markdown.render(this.text)
      return rendered
    },
    textEnglishRendered() {
      const rendered = markdown.render(this.text_en)
      return rendered
    },
    eventTimeZulu() {
      if (this.category === 'event') {
        return this.toZuluTime(this.eventDateTime)
      }
      return null
    },
    formOpenTimeZulu() {
      return this.toZuluTime(this.formOpenDateTime)
    },
    formCloseTimeZulu() {
      return this.toZuluTime(this.formCloseDateTime)
    },
  },
  methods: {
    ...mapActions(["sendEvent", "editEvent", "clearNotifications"]),
    toZuluTime(dateTimeString) {
      if (!dateTimeString) {
        return dateTimeString // Null datetime can be returned as is
      }
      return new Date(dateTimeString).toISOString() // Otherwise, convert local (Uppsala) time to zulu time
    },
    isEditing() {
      return this.eventID !== undefined
    },
    populateForm(data) {
      this.category = data.category
      this.title = data.title
      this.title_en = data.title_en
      this.subtitle = data.subtitle
      this.subtitle_en = data.subtitle_en

      this.text = data.text_raw
      this.text_en = data.text_en_raw
      this.eventDateTime = data.eventtime
      this.location = data.location
      this.location_en = data.location_en

      this.image = data.image
      this.bigimg = data.bigimg
      this.eventImg = data.image ? "url" : "ingen"

      this.form_id = data.form_id
      this.formOpenDateTime = data.form_opentime
      this.formCloseDateTime = data.form_closetime
      this.hasForm = !((data.form_id === "0") || !data.form_id)

      let scheduleDateTime = new Date(data.publishedtime)
      this.scheduledDateTime = scheduleDateTime.toLocaleString("sv-SE")
      this.scheduleRadioGroup = "schedule"
    },
    // Creates a file data object
    imageUpload(file) {
      let formData = new FormData()
      formData.append("file", file)
      this.fileSize = formData.get("file").size/(1024*1024) // Convert to MB

      this.fileData = formData
    },
    async submitEvent() {
      if (this.$refs.form.validate()) {
        // Engelska borde vara frivilligt. Så om "...en" är tom, lägg in Svenskan istället :)
        if (this.title_en === "") {
          this.title_en = this.title;
        }
        if (this.subtitle_en === "") {
          this.subtitle_en = this.subtitle;
        }
        if (this.text_en === "") {
          this.text_en = this.text;
        }
        if (this.location_en === "") {
          this.location_en = this.location;
        }

        // Vid fall av en nyhet ska vi ej ha med datum för event, samt inget form eller plats
        if (this.category === "news") {
          this.eventtime = ""
          this.form_id = 0
          this.formOpenDateTime = ""
          this.formCloseDateTime = ""
          this.location = ""
          this.location_en = ""
        }

        // TODO: Lägg till form_link i API:n
        if (this.form_link !== "") {
          this.form_id = this.form_link
        }

        // Delete any previous data
        this.fileData.delete("data")
      
        // Now we're ready to send the data!
        
        let dataObject = {
          title: this.title,
          title_en: this.title_en,
          subtitle: this.subtitle,
          subtitle_en: this.subtitle_en,
          text: this.textRendered,
          text_en: this.textEnglishRendered,
          text_raw: this.text,
          text_en_raw: this.text_en,
          category: this.category,
          eventtime: this.eventDateTime,
          location: this.location,
          location_en: this.location_en,
          bigimg: this.bigimg,
          form_id: this.form_id,
          form_opentime: this.formOpenDateTime ? this.formOpenDateTime : '', // Avoid not null constraint
          form_closetime: this.formCloseDateTime ? this.formCloseDateTime : '', // Avoid not null constraint
        }
        console.log(dataObject)

        if (this.isEditing()) {
          dataObject = {
            ...dataObject,
            id: this.eventID
          }
        }

        if (this.scheduleRadioGroup == "schedule" && this.scheduledDateTime) {
          // If schedule is choosen and a datetime has been selected, add the publishedtime field.
          // If not, the server will add the current datetime by default if we don't give it a publishedtime.
          dataObject = {
            ...dataObject,
            publishedtime: this.toZuluTime(this.scheduledDateTime)
          }
        }

        if (this.eventImg === "url"){
          dataObject = {
            ...dataObject,
            image: this.image
          }
        } else {
          // Set image to empty string regardless of the value of this.image
          // It's important because if we want to remove an image we can't let it be undefined
          // because then it won't be changed on the server.
          dataObject = {
            ...dataObject,
            image: ""
          }
        }

        this.fileData.append('data', JSON.stringify(dataObject))

        // Check if we are creating or editing an event 
        if (this.isEditing()) {
          console.log(dataObject)
          await this.editEvent(this.fileData)
        } else {
          await this.sendEvent(this.fileData)
        }

        // Notification
        notify(this)
      }
    },
  },
};
</script>

<style scoped>
.mainForm {
  margin: 20px;
  padding: 20px;
}

.center {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

v-card {
  margin: 1em;
}
</style>