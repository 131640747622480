<template>
  <v-row>
    <v-col cols="12" sm="6" md="6">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="dateLabel"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="dateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-menu
        ref="timepicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        v-model="timeMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="timeLabel"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:append-outer v-if="nullable">
              <v-icon @click="cancel">mdi-close</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-time-picker
          v-model="time"
          full-width
          format="24hr"
          @click:minute ="timeMenu = false"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: function () {
    return {
      date: this.value ? this.value.substring(0, 10) : null,
      time: this.value ? this.value.substring(11, 16) : null,
      dateMenu: false,
      timeMenu: false
    }
  },
  props: {
    dateLabel: String,
    timeLabel: String,
    value: String, // This is the param which is bound to the v-model
    // Assumed to be a string of the form YYYY-MM-DDTHH:MM. Usually not zulu time, but local Uppsala time of event!
    rules: { // TODO: implement these rules
      type: Array,
      required: false
    },
    nullable: Boolean
  },
  methods: {
    updateValue () {
      if (this.date && this.time) {
        const s = this.date + 'T' + this.time
        this.$emit('input', s)
      }
    },
    cancel () {
      this.date = null
      this.time = null
      this.$emit('input', null)
    }
  },
  watch: {
    date: function () {
      this.updateValue()
    },
    time: function () {
      this.updateValue()
    }
  }
}
</script>
